import * as React from 'react';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from 'styled-components';
import { normalize } from 'styled-normalize';

import { useGlobalStateContext } from '~/components/context/context';

type Props = {
  children?: React.ReactNode;
};

const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    text-decoration: none;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }

  body {
    font-size: 16px;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};;
    overscroll-behavior: none;
    overflow-x: hidden;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:hover {
        color: #00aa00;
      }
    }
  }
`;

const Layout = ({ children }: Props) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  const darkTheme: DefaultTheme = {
    background: '#000',
    text: '#fff',
    // accent: '#dd7732',
    // accent: '#00aa00',
    accent: 'rgba(238,235,235,0.91)',
    red: '#ea291e',
  };

  const lightTheme: DefaultTheme = {
    background: '#fff',
    text: '#000',
    accent: '#00aa00',
    red: '#ea291e',
  };

  const { currentTheme } = useGlobalStateContext() || { currentTheme: 'light' };
  const getThemeObject = (currentTheme) =>
    currentTheme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={getThemeObject(currentTheme)}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default Layout;
